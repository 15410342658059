// @flow
import * as React from 'react';
import styled from 'styled-components';
import { AnalyticsContext, getTrackingQueryString } from 'latitude-analytics';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { getUrlFromEnv } from '../../utils/getUrlFromEnvUtil';
import MarketingBanner from '../MarketingBanner/MarketingBanner';
import { FONT_FAMILY, FONT_WEIGHT } from '../../utils/constants';
import './_banner.scss';

const CC_APPLY_URL = getUrlFromEnv('funnel-start');
const ML_APPLY_URL = getUrlFromEnv('funnel-start-motor-loan');

/**
 * Renders banner content based on provided id
 *
 * @param {string} bannerId - identifier of banner so will return matching banner element
 * @param {number} key - react key id
 * @returns {React.element} rendered banner content
 */
export const renderBannerContent = (bannerId: string, key?: number) => {
  if (bannerId && bannerId.trim().length !== 0) {
    return <Banner id={bannerId} key={key} />;
  } else {
    return null;
  }
};

/*
 * Banner class. Renders a custom marketing banner with the given ID inside the header
 */

type Props = {
  id: string
};

const Banner = (props: Props) => {
  const [analytics] = React.useContext(AnalyticsContext);
  switch (props.id) {
    case 'car-loan':
      return (
        <StyledCarLoan
          navPromo
          title={
            <>
              Looking <br />
              for a new or <br /> used car?
            </>
          }
          desc={
            <>
              For those who don’t want to wait for a new set of wheels, take
              advantage of a Latitude car loan today.
            </>
          }
          primaryCta={
            <Link
              button={BUTTON_STYLE.TERTIARY_INVERSE}
              href="/loans/car-loan/"
              trackId="nav-banner-car-loan-more"
              trackProductId={['MLAULF-WEB']}
            >
              Find out more
            </Link>
          }
          secondaryCta={
            <Link
              button={BUTTON_STYLE.PRIMARY}
              href={`${ML_APPLY_URL}${getTrackingQueryString(analytics, {
                event: {
                  text: 'Apply Now',
                  location: 'Navigation Bar About Us'
                }
              })}`}
              trackId="nav-banner-car-loan-apply"
              trackProductId={['MLAULF-WEB']}
            >
              Apply Now
            </Link>
          }
          bgImg={require(`../../images/nav-banner-car.png`)}
          bgImgLarge={require(`../../images/nav-banner-car@2x.png`)}
        />
      );
    case 'credit-card':
      return (
        <StyledCreditCard
          navPromo
          titleTag="h3"
          title="No annual fee and free Global Wi-Fi"
          desc={<strong>28° Global is the only way to travel</strong>}
          image={
            <img
              className="marketing-banner__img"
              src={require('../../images/nav-banner-credit@2x.png')}
              style={{ maxHeight: '209px' }} // ie11 bug
              alt=""
            />
          }
          primaryCta={
            <Link
              href="https://www.28degreescard.com.au/"
              trackId="nav-banner-28d-more"
              button={BUTTON_STYLE.TERTIARY_INVERSE}
              small
              trackProductId={['CCAU28D']}
            >
              Find out more
            </Link>
          }
          secondaryCta={
            <Link
              href={`${CC_APPLY_URL}28degrees/`}
              trackId="nav-banner-28d-apply"
              button={BUTTON_STYLE.PRIMARY}
              small
              trackProductId={['CCAU28D']}
            >
              Apply now
            </Link>
          }
          bgImg={require(`../../images/28d-card-bg.png`)}
          bgImgLarge={require(`../../images/28d-card-bg.png`)}
        />
      );
    default:
      return null;
  }
};

const StyledCarLoan = styled(MarketingBanner)`
  background: linear-gradient(111deg, #0060ff 19%, #0046aa 128%) !important;

  &.marketing-banner--nav-promo {
    .marketing-banner__content {
      .marketing-banner__title {
        font-family: ${FONT_FAMILY.TITLE};
        font-weight: ${FONT_WEIGHT.SEMI_BOLD};
        margin-top: auto;
        font-size: 28px !important;
        line-height: 1.1 !important;
      }
    }
  }

  .marketing-banner__buttons {
    margin-top: 10px;
  }

  .marketing-banner__cta a {
    max-width: 120px;
    min-width: auto;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
  }

  .marketing-banner__bg-img,
  .marketing-banner__bg-img-large {
    background-image: url(${require('../../images/nav-banner-car.png')}) !important;
    background-size: calc(100% - 60px);
    background-position: top right;
    background-repeat: no-repeat;
  }
`;

const StyledCreditCard = styled(MarketingBanner)`
  background-image: url(${require('../../images/nav-banner-credit-bg.svg')}) !important;
  background-size: cover;
  background-position: center;

  .marketing-banner__title {
    font-family: ${FONT_FAMILY.TITLE};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }

  .marketing-banner__image {
    order: -2;
  }

  .marketing-banner__desc {
    margin-bottom: 26px !important;
  }

  .marketing-banner__bg-img,
  .marketing-banner__bg-img-large {
    display: none !important;
  }

  .marketing-banner__cta a {
    max-width: 120px;
    min-width: auto;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
  }
`;

export default Banner;
