import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_marketing-banner.scss';

const MarketingBanner = props => {
  // css class
  const className = classnames(
    'marketing-banner',
    { 'marketing-banner--height-small': props.heightSmall },
    { 'marketing-banner--width-half': props.widthHalf },
    { 'marketing-banner--bg-gradient-blue': props.bgGradientBlue },
    { 'marketing-banner--bg-gradient-pink': props.bgGradientPink },
    { 'marketing-banner--buttons-stacked-mobile': props.buttonsStackedMobile },
    { 'marketing-banner--betterer-image': props.bettererImage },
    { 'marketing-banner--card-image': props.cardImage },
    { 'marketing-banner--image': props.image },
    { 'marketing-banner--nav-promo': props.navPromo },
    props.className
  );

  // title
  const Title = props.titleTag ? props.titleTag : 'h1';

  // background images
  const bgImg = props.bgImg ? { backgroundImage: `url("${props.bgImg}")` } : {};
  const bgImgLarge = props.bgImgLarge
    ? { backgroundImage: `url("${props.bgImgLarge}")` }
    : {};

  // background image styles - if you want to manually transform the styles with css
  const bgImgStyle = { ...bgImg, ...props.bgImgStyle };
  const bgImgLargeStyle = { ...bgImgLarge, ...props.bgImgLargeStyle };

  return (
    <div className={className}>
      <div className="marketing-banner__inner">
        <div className="marketing-banner__content">
          {props.title && (
            <Title className="marketing-banner__title">{props.title}</Title>
          )}
          {props.subTitle && (
            <p className="marketing-banner__sub-title ">{props.subTitle}</p>
          )}
          {props.desc && (
            <p className="marketing-banner__desc ">{props.desc}</p>
          )}
          {props.disclaimer && (
            <p className="marketing-banner__disclaimer">{props.disclaimer}</p>
          )}
          {props.image && (
            <div className="marketing-banner__image">{props.image}</div>
          )}
          {props.cardImage && (
            <div className="marketing-banner__card">{props.cardImage}</div>
          )}
          {props.bettererImage && (
            <div className="marketing-banner__betterer-image">
              {props.bettererImage}
            </div>
          )}
          <div className="marketing-banner__buttons">
            <div
              className={`marketing-banner__cta ${
                props.wideButtons ? 'marketing-banner__cta--large' : ''
              }`}
            >
              {props.primaryCta}
            </div>
            <div
              className={`marketing-banner__cta ${
                props.wideButtons ? 'marketing-banner__cta--large' : ''
              }`}
            >
              {props.secondaryCta}
            </div>
          </div>
        </div>
      </div>

      {props.bgImg && (
        <div className="marketing-banner__bg-img" style={bgImgStyle} />
      )}

      {props.bgImgLarge && (
        <div
          className="marketing-banner__bg-img-large"
          style={bgImgLargeStyle}
        />
      )}
    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
MarketingBanner.propTypes = {
  componentType: PropTypes.string,
  image: PropTypes.node,
  title: PropTypes.node,
  titleTag: PropTypes.string,
  subTitle: PropTypes.node,
  desc: PropTypes.node,
  primaryCta: PropTypes.node,
  secondaryCta: PropTypes.node
};

export default MarketingBanner;
