import React from 'react';

export default props => {
  let page = props.location.pathname.replace(/^\/|\/$/g, '');
  if (!page) {
    page = 'index';
  }
  let title;
  try {
    // eslint-disable-next-line import/no-dynamic-require
    title = require(`../../data/pages/${page}.json`).title;
  } catch (e) {
    // continue regardless of error
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb sr-only">
        {page !== 'index' && (
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
        )}
        <li className="breadcrumb-item active" aria-current="page">
          {title}
        </li>
      </ol>
    </nav>
  );
};
