// @flow
import React from 'react';
import { BaseHeader } from '@latitude/base-header';
import HeaderData from '../../data/json/Navigation/navigation.json';
import { renderBrandButton } from './BrandButton';
import { renderBannerContent } from './Banner';
import renderLoginModalImage from './LoginModalImage';
import './_lfs-header.scss';

// get LFS header data chunks
const navTreeData: {} = HeaderData.navTree;
const loginModalsData: [] = HeaderData.loginModals;
const loginFormData: {} = HeaderData.loginForm;
const cardTypesData: [] = HeaderData.cardTypes;
const searchFormData: {} = HeaderData.searchForm;

const LfsHeader = (props: {}) => (
  <BaseHeader
    navTreeData={navTreeData || {}}
    loginModalsData={loginModalsData || []}
    loginFormData={loginFormData || {}}
    cardTypesData={cardTypesData || []}
    searchFormData={searchFormData || {}}
    renderBrandButton={renderBrandButton}
    renderBanner={renderBannerContent}
    renderLoginModalImage={renderLoginModalImage}
    {...props}
  />
);

export default LfsHeader;
