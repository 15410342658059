// @flow

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import AppendHead from 'react-append-head';
import queryString from 'query-string';
import { Footer } from '@latitude/footer';
import footerData from '../data/json/footer.json';
import LfsHeader from './Header/LfsHeader';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import { saveLoanPartnerName } from '../utils/helpers';
import '../../sass/style.scss';

type Props = {};

const TemplateWrapper = (props: Props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryParams = queryString.parse(location.search);
      const param = queryParams['partnername'];
      return saveLoanPartnerName(param);
    }
  }, []);

  return (
    <React.Fragment>
      <AppendHead>
        <script
          name="datadog-main"
          order="0"
          src="https://www.datadoghq-browser-agent.com/datadog-rum-us.js"
          type="text/javascript"
        />
        <script name="datadog-client" order="1">
          {`
            window.DD_RUM.init({
              clientToken: 'pub5fb466c39da25b58933bac3aeb55bacf',
              applicationId: '005f5c8e-1c16-4666-b410-75d4cfb48965',
            });
            `}
        </script>

        {/* <!-- Hotjar Tracking Code --> */}
        <script name="hotjar" order="2">
          {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:826763,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </AppendHead>
      <Helmet>
        <link rel="canonical" href="https://www.latitudefinancial.com.au/" />
        <title>
          Personal Loans, Credit Cards &amp; Insurance - Latitude Financial
          Services
        </title>
        <meta
          name="description"
          content="New Name. Even better service. Latitude Financial Services offer personal loans, credit cards, and insurance solutions to help you get what you need."
        />
        <meta
          name="keywords"
          content="GE Money, Personal Loans, credit cards, GE Finance, GE Australia, Latitude Financial Services"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <!-- Google Fonts --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,800,600&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {!props.noHeader && <LfsHeader />}
      {!props.noBreadcrumb && <Breadcrumb {...props} />}
      {props.children}

      {!props.noFooter && (
        <Footer
          socialLinks={footerData.socialLinks}
          siteLinks={footerData.siteLinks}
          appLinks={footerData.appLinks}
          footerLinks={footerData.footerLinks}
          finePrint={footerData.finePrint}
        />
      )}
    </React.Fragment>
  );
};

export default TemplateWrapper;
