// @flow
import React from 'react';
import ReactHTMLParser from 'react-html-parser';

export const pxAsNumber = (px: string): number => {
  return parseInt(px.replace('px', ''), 10);
};

export const getKeyByValue = (
  object: { [key: string]: number | string },
  value: number | string
): ?string => {
  return Object.keys(object).find((key: string) => object[key] === value);
};

export const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// OP-186 Store the partnername to append to apply links, used by the Salesforce application
export const saveLoanPartnerName = (partnerName: string) => {
  if (partnerName) {
    sessionStorage.setItem('partnername', partnerName);
  }
};

export const getLoanTermText = (term: number): string => {
  if (typeof term !== 'number' || isNaN(term) || term < 0) {
    return '';
  }
  return `${term} year${term > 1 ? 's' : ''}`;
};

export const stringToBoolean = (value: string): string | boolean => {
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return value;
};

/*
 * Returns an object containing the Google Analytics Client ID
 *
 * Calling this function returns an object with key and values representing query param name and value respectively.
 * {
 *   _ga: '2.156895549.786352505.1571118984-861204910.1571118984'
 * }
 */
export const getGoogleAnalyticsParam = () => {
  let params = {};
  if (typeof ga !== 'undefined') {
    // eslint-disable-next-line no-undef, flowtype/require-parameter-type
    ga(function(tracker) {
      let qs = tracker.get('linkerParam').split('&');
      // eslint-disable-next-line flowtype/no-weak-types
      params = qs.reduce((obj: object, value: string) => {
        const parts = value.split('=');
        obj[parts[0]] = parts[1];
        return obj;
      }, {});
    });
  }
  return params;
};

/*
 * Returns an object containing the Adobe Analytics Client ID
 *
 * Calling this function returns an object with key and values representing query param name and values respectively.
 * {
 *   adobe_mc: '2.156895549.786352505.1571118984-861204910.1571118984'
 * }
 */
export const getAdobeAnalyticsParam = (baseUrl: string) => {
  // eslint-disable-next-line no-undef
  if (typeof Visitor !== 'undefined') {
    // Cross domain tracking adobe analytics
    // eslint-disable-next-line no-undef
    const visitor = Visitor.getInstance('B6D9B74F57B2FBE97F000101@AdobeOrg', {
      trackingServer: 'metrics.latitudefinancial.com',
      trackingServerSecure: 'smetrics.latitudefinancial.com',
      marketingCloudServer: 'metrics.latitudefinancial.com',
      marketingCloudServerSecure: 'smetrics.latitudefinancial.com'
    });
    const destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(baseUrl);
    return {
      adobe_mc: decodeURIComponent(
        destinationURLWithVisitorIDs.match(/adobe_mc=([^&?]+)/)[1]
      )
    };
  } else {
    return {};
  }
};

export const parseContent = (content: Item.content) => {
  if (React.isValidElement(content)) {
    return content;
  }
  if (typeof content === 'string') {
    return ReactHTMLParser(content);
  }
  return '';
};

/**
 * Accepts a number value and converts it to a readable percentage. Defaults to two decimal places.
 * Useful for displaying interest rates.
 * Returns a string.
 *
 * eg '10.5773' returns '10.58%'
 * eg '0.23' returns '0.23%'
 */

export const numberToPercentage = (number: string, decimalPlaces: number = 2) =>
  `${parseFloat(number).toFixed(decimalPlaces)}%`;
